.App {
  margin: 0 auto 0 auto;
  padding: 40px;
  background-color: #ffffff;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(8px + 1vmin);
  color: #333333;
}
.step {
  //min-height: 100vh;
  background-color: #f8f8f8;
}

/*a.ms-Link, a.ms-Link:visited, .App-link {
  color: #f59c00;
}*/

h1, h2, h3 {
  color: #6f6f6f;
}
h1 {
  margin-left: 1em;
  margin-right: 1em;
  font-size: 38px;
  font-weight: 200;
  text-align: center;
}
.dropzone {
  border: 1px dashed #a5a5a5;
  height: 95px;
  text-align: center;
  vertical-align: middle;
  color: #b7b7b7;
}
.dropzone-active {
  background-color: #ffffff;
  opacity: 0.8;
  background-size: 20px 20px;
  background-image: repeating-linear-gradient(-45deg, #f59c00 0, #f59c00 2px, #ffffff 0, #ffffff 50%);
  width: 100%;
  height: 100%
}